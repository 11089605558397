.projectsSection {
	margin: 30vh 0;
	display: flex;
	flex-direction: column;
}

.projectsSection h2 {
	color: whitesmoke;
	text-align: center;
	font-size: 2em;
}

.cardsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.moreProjectsBtn {
	width: 30vw;
	max-width: 300px;
	height: 7vh;
	max-height: 60px;
	margin: 0 auto;
	position: relative;
	top: 35px;
}

@media (min-width: 1150px) {
	.cardsContainer {
		display: flex;
		justify-content: space-around;
	}
}
