.container {
	width: 400px;
	height: 75px;
	border: 1px solid red;
	background-color: black;
	color: white;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1% 3%;
}
