::root {
	--backgroundColor: rgb(18, 18, 18);
}

.App {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	background-color: rgb(18, 18, 18);
}

.content {
	overflow-x: hidden;
	padding: 5px;
}

.footer {
	width: 100%;
	height: 50px;
	border-top: red 2px solid;
	color: red;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
}

.footer p {
	margin: 0;
}

@media (max-width: 525px) {
	/* .App {
		flex-direction: row;
	} */

	.content {
		/* margin-left: 110px; */
	}
}

/* @media (min-width: 1200px) {
	.content {
		margin: 0 10%;
	}
} */

@media (min-width: 550px) {
	.content {
		margin-left: 150px;
	}
}

@media (min-width: 2500px) {
	.content {
		margin: 0 12%;
	}
}

@media (min-width: 3000px) {
	.content {
		margin: 0 18%;
	}
}
