.animatedBtn {
	position: relative;
	font-family: "Electrolize", sans-serif;
	font-size: 1em;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	line-height: 1;
	color: red;
	margin: 0;
	padding: 0.5em;
	background: none;
	border: none;
	cursor: pointer;

	&:hover {
		color: lighten(red, 20%);
	}
	&:focus {
		color: lighten(red, 10%);
		outline: none;
	}
	&:active {
		color: red;
	}

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
	}

	&:before {
		top: 0;
		left: 0;
		box-shadow: inset 1px 1px 0 0 red;
	}
	&:after {
		right: 0;
		bottom: 0;
		box-shadow: inset -1px -1px 0 0 red;
	}
}

.animatedBtn:hover {
	&:before {
		animation: hoverShadowBefore 1s forwards;
	}
	&:after {
		animation: hoverShadowAfter 1s forwards;
	}
}

.animatedBtn:disabled {
	color: grey;
	cursor: not-allowed;
	opacity: 0.5;

	&:before {
		animation: none;
	}
	&:after {
		animation: none;
	}
}

@keyframes hoverShadowBefore {
	0% {
		width: 100%;
		height: 1px;

		top: 0;
		left: 0;
	}
	33% {
		width: 1px;
		height: 100%;

		top: 0;
		left: 0;
	}
	66% {
		width: 1px;
		height: 1px;

		top: calc(100% - 1px);
		left: 0;
	}
	100% {
		width: 100%;
		height: 1px;

		top: calc(100% - 1px);
		left: 0;
	}
}

@keyframes hoverShadowAfter {
	0% {
		width: 100%;
		height: 1px;
	}
	33% {
		width: 1px;
		height: 100%;

		bottom: 0;
		right: 0;
	}
	66% {
		width: 1px;
		height: 1px;

		bottom: calc(100% - 1px);
		right: 0;
	}
	100% {
		width: 100%;
		height: 1px;

		bottom: calc(100% - 1px);
		right: 0;
	}
}
