.aboutSection {
	background-color: rgb(18, 18, 18);
	padding: 10%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.aboutSection h1 {
	color: white;
	font-size: 7vw;

	margin: 0;
}

.intro {
	padding-top: 2vh;
}

.aboutMeContainer {
	display: flex;
	flex-direction: column;
}

.aboutMeContainer h3 {
	color: white;
	font-family: "Open Sans";
	font-size: 1em;
	text-decoration: underline;
}

.aboutMe {
	color: white;
	margin: 3vh 0;
	font-family: "Open Sans";
	font-size: 24px;
	padding-right: 20px;
}

.aboutMeSub {
	display: list-item;
	margin-left: 1em;
	color: white;
	font-size: 20px;
}

.buttonContainer {
	margin: 2em 1em 1em 1em;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
	max-width: 350px;
}

.buttonContainer button {
	background-color: #fcfcfd;
	border-radius: 4px;
	border-width: 0;
	box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
	box-sizing: border-box;
	color: #36395a;
	cursor: pointer;
	font-family: "JetBrains Mono", monospace;
	max-height: 48px;
	width: 140px;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 18px;
	flex-shrink: 1;
	margin: 0 auto;
}

.buttonContainer button:hover {
	box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
	transform: translateY(-2px);
}

.slideHeader {
	height: 0;
}

.slideHeader p {
	left: 280px !important;
}

.aboutSummaryContainer {
	position: relative;
}

@media (min-width: 390px) {
	.aboutSection::before,
	.aboutSection::after {
		content: "";
		width: 80%;
		height: 2px;
		background-color: red;
		position: absolute;
		animation: lineGrow80 1.2s forwards;
	}

	.aboutSection::before {
		bottom: 5vh;
		right: 0;
	}

	.aboutSection::after {
		bottom: 2vh;
		left: 0;
	}
}

@media (max-width: 450px) {
	.buttonContainer {
		height: auto;
	}

	.aboutSection h1 {
		font-size: 2.5em;
	}

	.aboutMe {
		font-size: 20px;
	}

	.aboutMeSub {
		font-size: 18px;
	}
}

@media (min-width: 550px) {
	.aboutSection {
		padding: 0;
	}

	.aboutSection {
		height: 100vh;
	}
}

@media (min-width: 600px) {
	.intro {
		padding-top: 5vh;
		margin-left: 5%;
	}

	.buttonContainer {
		height: auto;
		margin-top: 7vh;
	}

	.aboutMeContainer {
		padding: 5% 0 0 0;
		margin-left: 5%;
	}

	.aboutMe {
		font-size: 22px;
	}

	.aboutSection::before,
	.aboutSection::after {
		content: "";
		width: 80%;
		height: 2px;
		background-color: red;
		position: absolute;
		animation: lineGrow80 1.2s forwards;
	}

	.aboutSection::before {
		bottom: 5vh;
	}

	.aboutSection::after {
		bottom: 2vh;
	}

	@keyframes lineGrow80 {
		0% {
			width: 0%;
		}

		100% {
			width: 80%;
		}
	}
}

@media (max-width: 900px) {
	.aboutSection::after,
	.aboutSection::before {
		width: 70%;
		animation: lineGrow70 1.2s forwards;
	}

	@keyframes lineGrow70 {
		0% {
			width: 0%;
		}

		100% {
			width: 70%;
		}
	}

	.aboutMe {
		margin-top: 0;
	}
}

@media (min-width: 1050px) {
	.aboutSection h1 {
		font-size: 4em;
	}

	@keyframes lineGrow40 {
		0% {
			width: 0%;
		}

		100% {
			width: 40%;
		}
	}
}

@media (min-width: 1240px) {
	.aboutSection {
		height: 100vh;
	}

	.aboutMeContainer {
		width: 50%;
		justify-content: center;
		position: absolute;
		right: 2vw;
		bottom: 20vh;
		z-index: 2;
	}

	.aboutMeSub {
		position: relative;
		right: 60%;
	}

	.aboutSummaryContainer {
		right: -5vw;
		bottom: 10vh;
	}

	.aboutMe {
		width: 30vw;
		min-width: 415px;
		max-width: 500px;
		position: relative;
		margin: 20px 0px 0 0;
		background-color: black;
		padding: 10px;
		z-index: 2;
		font-size: 22px;
	}

	.aboutSummaryContainer::before {
		content: "";
		position: absolute;
		bottom: 5vh;
		left: 4vw;
		background-color: red;
		z-index: -2;
		height: 70%;
		max-height: 200px;
		width: 30vw;
		min-width: 415px;
		max-width: 500px;
	}
}
