.modal {
	z-index: 100;

	position: fixed;
	top: 5%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	box-shadow: 0 2px 8px #00000042;
	border-radius: 8px;
	padding: 10px;
}

.header {
	width: 100%;
	padding: 1rem 0.5rem;
	color: white;
	text-align: center;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header h2 {
	position: relative;
	left: 3vw;
}

.header button {
	background: none;
	border: none;
	color: white;
	font-size: 2em;
	font-weight: bold;
	width: fit-content;
	padding: 2px 10px;
	cursor: pointer;
}

.header button:hover {
	color: red;
}

.header h2 {
	margin: 0.5rem;
}

.content {
	padding: 1rem 0.5rem;
}

.footer {
	padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
	.modal {
		width: 40rem;
	}
}

.modal-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.modal-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.modal-exit {
	transform: translateY(0);
	opacity: 1;
}

.modal-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}
