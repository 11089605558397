.card {
	width: 75%;
	max-width: 400px;
	border: 2px solid red;
	height: 60vh;
	min-height: 500px;
	max-height: 550px;
	margin: 5vh 1%;
	border-radius: 10px;
	box-shadow: 6px 6px 6px 0px black;
	padding: 0 0 3% 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cardImg {
	background-color: black;
	width: 100%;
	height: 40%;
	border-radius: 10px;
	border-bottom: 2px solid red;
	box-sizing: border-box;
}

.cardImg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.cardDescription {
	border-radius: 10px;
	text-align: center;
	padding: 2% 2% 4% 2%;
}

.cardDescription p {
	color: white;
}

.tags {
	display: flex;
	justify-content: center;
	gap: 2%;
	flex-wrap: wrap;
}

.buttonContainer {
	display: flex;
	justify-content: space-around;
	gap: 5px;
	margin-top: 10%;
}

.buttonContainer button a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
